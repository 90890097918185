/* Backgounds */
.bg-card-filters {

  background:
    radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, rgba(48, 52, 255, 0.8) 68% 70%, #0000 72%) 32px 32px/calc(2*32px) calc(2*32px),
    radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, rgba(48, 52, 255, 0.8) 68% 70%, #0000 72%) 32px 32px/calc(2*32px) calc(2*32px),
    radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, rgba(48, 52, 255, 0.8) 68% 70%, #0000 72%) 0 0/calc(2*32px) calc(2*32px),
    radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, rgba(48, 52, 255, 0.8) 68% 70%, #0000 72%) 0 0/calc(2*32px) calc(2*32px),
    repeating-conic-gradient(#474bff 0 25%, #0000 0 50%) 0 0/calc(2*32px) calc(2*32px),
    radial-gradient(#0000 66%, rgba(48, 52, 255, 0.8) 68% 70%, #0000 72%) 0 calc(32px/2)/32px 32px #474bff;

}

.bg-card-filters-combos {

  background:
    radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, #008b41 68% 70%, #0000 72%) 32px 32px/calc(2*32px) calc(2*32px),
    radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, #008b41 68% 70%, #0000 72%) 32px 32px/calc(2*32px) calc(2*32px),
    radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, #008b41 68% 70%, #0000 72%) 0 0/calc(2*32px) calc(2*32px),
    radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, #008b41 68% 70%, #0000 72%) 0 0/calc(2*32px) calc(2*32px),
    repeating-conic-gradient(#009144 0 25%, #0000 0 50%) 0 0/calc(2*32px) calc(2*32px),
    radial-gradient(#0000 66%, #008b41 68% 70%, #0000 72%) 0 calc(32px/2)/32px 32px #009144;

}


.bg-section-filters-promo {
  background:
    radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, #fe8300 68% 70%, #0000 72%) 32px 32px/calc(2*32px) calc(2*32px),
    radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, #fe8300 68% 70%, #0000 72%) 32px 32px/calc(2*32px) calc(2*32px),
    radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, #fe8300 68% 70%, #0000 72%) 0 0/calc(2*32px) calc(2*32px),
    radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, #fe8300 68% 70%, #0000 72%) 0 0/calc(2*32px) calc(2*32px),
    repeating-conic-gradient(#ff9500 0 25%, #0000 0 50%) 0 0/calc(2*32px) calc(2*32px),
    radial-gradient(#0000 66%, #fe8300 68% 70%, #0000 72%) 0 calc(32px/2)/32px 32px #ff9500;
}

.text-color-promo {
  color: #fe8300 !important;
  background-color: #fff5d3;
}


.bg-section-filter-segments {
  background:
    radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, #c300ff 68% 70%, #0000 72%) 32px 32px/calc(2*32px) calc(2*32px),
    radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, #c300ff 68% 70%, #0000 72%) 32px 32px/calc(2*32px) calc(2*32px),
    radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, #c300ff 68% 70%, #0000 72%) 0 0/calc(2*32px) calc(2*32px),
    radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, #c300ff 68% 70%, #0000 72%) 0 0/calc(2*32px) calc(2*32px),
    repeating-conic-gradient(#b400ea 0 25%, #0000 0 50%) 0 0/calc(2*32px) calc(2*32px),
    radial-gradient(#0000 66%, #c300ff 68% 70%, #0000 72%) 0 calc(32px/2)/32px 32px #b400ea;

}

.text-color-segments {
  color: #c300ff !important;
  background-color: #f9e3ff;
}

.bg-section-filter-items {
  background:
    radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, #202020 68% 70%, #0000 72%) 32px 32px/calc(2*32px) calc(2*32px),
    radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, #202020 68% 70%, #0000 72%) 32px 32px/calc(2*32px) calc(2*32px),
    radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, #202020 68% 70%, #0000 72%) 0 0/calc(2*32px) calc(2*32px),
    radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, #202020 68% 70%, #0000 72%) 0 0/calc(2*32px) calc(2*32px),
    repeating-conic-gradient(#000000 0 25%, #0000 0 50%) 0 0/calc(2*32px) calc(2*32px),
    radial-gradient(#0000 66%, #202020 68% 70%, #0000 72%) 0 calc(32px/2)/32px 32px #000000;

}

.bg-section-filter-product {

  background:
    radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, #00159f 68% 70%, #0000 72%) 32px 32px/calc(2*32px) calc(2*32px),
    radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, #00159f 68% 70%, #0000 72%) 32px 32px/calc(2*32px) calc(2*32px),
    radial-gradient(35.36% 35.36% at 100% 25%, #0000 66%, #00159f 68% 70%, #0000 72%) 0 0/calc(2*32px) calc(2*32px),
    radial-gradient(35.36% 35.36% at 0 75%, #0000 66%, #00159f 68% 70%, #0000 72%) 0 0/calc(2*32px) calc(2*32px),
    repeating-conic-gradient(#001391 0 25%, #0000 0 50%) 0 0/calc(2*32px) calc(2*32px),
    radial-gradient(#0000 66%, #00159f 68% 70%, #0000 72%) 0 calc(32px/2)/32px 32px #001391;


}

.text-color-products {
  color: #00159f !important;
  background-color: #cfe6ff;
}


.scale-in-ver-top{animation:scale-in-ver-top .5s cubic-bezier(.25,.46,.45,.94) both}
@keyframes scale-in-ver-top{0%{transform:scaleY(0);transform-origin:100% 0;opacity:1}100%{transform:scaleY(1);transform-origin:100% 0;opacity:1}}




.input-icon-container {
  position: relative;
  width: 300px;
}

.input-icon-container input {
  width: 100%;
  padding: 10px; /* Espacio a la derecha para el ícono */
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.input-icon-container .icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}


/* Card List Prodducts  */
.imgitemsList {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 12px;
}


.card-item-categorie-title {
  width: 200px;
}

.card-item-categorie-description {
  width: 210px;
}

.btn-add-product-list {
  border: 2px dashed #b2d7ff;
  cursor: pointer;
  color: #0039d7;
  width: 417px;
  height: 146px;
}

.btn-add-product-list:hover {
  background-color: #e8f4ff;
  border: 3px dashed #0039d7;
  cursor: pointer;
}

.nav-scroll {
  display: flex;
  overflow-x: auto;
  /* Habilitar el scroll horizontal */
  white-space: nowrap;
  /* Evitar que los elementos se rompan a la siguiente línea */
}

.nav-scroll::-webkit-scrollbar {
  height: 6px;
  /* Altura de la barra de desplazamiento */
}

.nav-scroll::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* Color de la barra de desplazamiento */
  border-radius: 10px;
}

.nav-item {
  flex-shrink: 0;
  /* Prevenir que los elementos se reduzcan demasiado */
}

/*~ lIST CATEGORIES  */


.container-list-kiosk_section {
  overflow: scroll;
  overflow-x: auto;
  white-space: nowrap;
  position: relative;
}


.container-list-kiosk::-webkit-scrollbar {
  width: 2px !important;
}

.container-list-kiosk ul {
  list-style: none;
  margin: 10px;
  padding: 0;
}

.container-list-kiosk li {
  display: inline-block;
  margin: 1px 9px;
}

.container-list-kiosk label {
  width: auto;
  font-size: 20px;
}

.horizontal-scroll {
  overflow-x: auto;
  /* Habilitar scroll horizontal */
  white-space: wrap;
  /* Evitar que los elementos se rompan a la siguiente línea */
  display: flex;
  /* Mantener los elementos en línea */
  flex-wrap: nowrap !important;
}

.horizontal-scroll::-webkit-scrollbar {
  height: 0px;
  visibility: visible;
  /* Altura de la barra de desplazamiento */
}

.horizontal-scroll::-webkit-scrollbar-thumb {
  background-color: #0600a85b;
  /* Color de la barra de desplazamiento */
  border-radius: 10px;
}

.horizontal-scroll>.nav-sp-item {
  flex-shrink: 0;
  /* Evita que los elementos se encojan */
}


.scale-in-ver-top {
  animation: scale-in-ver-top .5s cubic-bezier(.25, .46, .45, .94) both
}

@keyframes scale-in-ver-top {
  0% {
    transform: scaleY(0);
    transform-origin: 100% 0;
    opacity: 1
  }

  100% {
    transform: scaleY(1);
    transform-origin: 100% 0;
    opacity: 1
  }
}

@media (max-width: 1180px) {
  .card-item-categorie-title {
    width: 160px;
  }

  .card-item-categorie-description {
    width: 160px;
  }
}

@media (max-width: 1024px) {
  .card-item-categorie-title {
    width: 148px;
  }

  .card-item-categorie-description {
    width: 160px;
  }
}

@media (max-width: 767px) {
  .card-item-categorie-title {
    width: 160px;
  }



  .btn-flotante {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 30px;
    padding: 20px;
    border: none;
    background-color: #1e65e9;
    color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 2px;
    position: fixed;
    bottom: 4px;
    right: 4px;
    transition: all 300ms ease 0ms;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    z-index: 99;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    -webkit-transition: all 300ms ease 0ms;
    -moz-transition: all 300ms ease 0ms;
    -ms-transition: all 300ms ease 0ms;
    -o-transition: all 300ms ease 0ms;
  }

  .btn-flotante:active {
    background-color: #2c2fa5;
    /* Color de fondo al pasar el cursor */
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
    transform: translateY(-7px);
  }
}