/* Off-canvas container */
.off-canvas {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  transition: 0.3s ease-in-out;
  z-index: 2000;
  padding-top: 0;
  overflow: hidden;
}

.off-canvas.open {
  left: 0;
}

.off-canvas-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #444;
  padding: 1px 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1100;
}

.off-canvas-header h2 {
  margin: 0;
}

.close-btn {
  font-size: 30px;
  cursor: pointer;
  color: rgb(255, 0, 0);
}

.off-canvas-content {
  height: 100vh;
  overflow-y: scroll;
  position: relative;
}

.off-canvas-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.off-canvas-content li {
  padding: 15px;
}

/* Button to open the off-canvas */
.open-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 1100;
}

/* Ocultar el icono predeterminado */
details summary::-webkit-details-marker,
details summary::marker {
  display: none;
  content: "";
}

/* Agregar un nuevo icono */
summary:before {
  content: "";
  /* Aquí se puede cambiar el contenido para el nuevo icono */
  display: inline-block;
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
  
}

/* Cambiar el icono cuando el contenido está abierto */
details[open] summary:before {
  color: #008080;
  transform: rotate(0deg);
}


details {
  border: none;
  /* border-radius: 0.5rem; */

  transition: 50ms;
}
details[open] {
    padding-bottom: 1em;
}  

summary::before,
summary::after {
    transition: 200ms;
  }



@layer markers {
  summary {
  position: relative;
}
summary::marker {
  content: none;
}
summary::before,
summary::after {
  content: '';
}
summary::before,
summary::after {
    width: .75em;
    height: 0;
    border-bottom: 2px solid;
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    transform: translateY(-50%);
  }
summary::after {
  transform: rotate(90deg);
  transform-origin: 50% 50%;
}
[open] summary::after {
  transform: rotate(0deg);
}
}

/* styles ported over from the second example */
@layer step2styles {
  details {
    border: 1px solid;
    padding: 0 1rem;
    background: white;
  }
  details + details {
    border-top: none;
  }
  details[open] {
      padding-bottom: 1em;
  }  
  summary {
    padding: 0;
    font-size: 1.25rem;
    font-weight: bold;
    cursor: pointer;
  }
}

