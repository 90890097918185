/* Texts */

.card-title {
    color: black;
}

.card-value-total {
    position: absolute;
    left: 25px;
    bottom: 12px;
    font-weight: 700;
    font-size: 50px;
    line-height: 77px;
}


.banner-sp-analitics {
    background-color: #d9f1ff;
    border: 1px solid #59c3ff;
}

.bg-section-analitics {
background:
      radial-gradient(35.36% 35.36% at 100% 25%,#0000 66%,#000000 68% 70%,#0000 72%) 32px 32px/calc(2*32px) calc(2*32px),
      radial-gradient(35.36% 35.36% at 0    75%,#0000 66%,#000000 68% 70%,#0000 72%) 32px 32px/calc(2*32px) calc(2*32px),
      radial-gradient(35.36% 35.36% at 100% 25%,#0000 66%,#000000 68% 70%,#0000 72%) 0 0/calc(2*32px) calc(2*32px),
      radial-gradient(35.36% 35.36% at 0    75%,#0000 66%,#000000 68% 70%,#0000 72%) 0 0/calc(2*32px) calc(2*32px),
      repeating-conic-gradient(#0f0f0f 0 25%,#0000 0 50%) 0 0/calc(2*32px) calc(2*32px),
      radial-gradient(#0000 66%,#000000 68% 70%,#0000 72%) 0 calc(32px/2)/32px 32px
      #0f0f0f;




}


/* Blur */
.bg-blur-sp {
    background-color: #29292946;
    backdrop-filter: blur(5px);
    /* border: 1px solid #e4e4e4a2; */
    /* box-shadow: 0px 0px 5px #222222; */
}

.bg-blur-sp-2 {
    background-color: rgba(177, 177, 177, 0.15);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    color: #fff;
    /* border: 1px solid #e4e4e4a2; */
    /* box-shadow: 0px 0px 5px #222222; */
}

.bg-dash-blur {
    background-color: #111927;
    background-image:
        radial-gradient(at 47% 33%, hsl(162.00, 77%, 40%) 0, transparent 59%),
        radial-gradient(at 82% 65%, hsl(218.00, 39%, 11%) 0, transparent 55%);
}

/* Glassmorphism card effect */
.bg-card-blur {
    background-color: #0040cb63;
    backdrop-filter: blur(-10px);
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border: 1px solid #e7e7e747;
}


/* Cards */

.card-analitics-sp {
    width: 90%;
    height: 165px;
    position: relative;
    padding: 25px;
/* background: radial-gradient(178.94% 106.41% at 100% 100%, #bcddf6 10%, rgba(255, 255, 255, 0) 41.88%), #FFFFFF;  */

/* background: radial-gradient(178.94% 106.41% at 26.42% 106.41%, #bff6bc 0%, rgba(255, 255, 255, 0) 21.88%), #FFFFFF; */



    /* box-shadow: 0px 155px 62px rgba(0, 0, 0, 0.01), 0px 87px 52px rgba(0, 0, 0, 0.05), 0px 39px 39px rgba(0, 0, 0, 0.09), 0px 10px 21px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1); */


    border-radius: 23px;


    transition: all 0.8s cubic-bezier(0.15, 0.83, 0.66, 1);


    cursor: pointer;

    border: .5px solid #2bc12625;
}

.card-analitics-sp:hover {
    transform: scale(1.05);
      border: .5px solid #2bc126c7;
}

/* 
.container {
    width: 250px;
    height: 250px;
    position: absolute;
    right: -35px;
    top: -50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0.7);
} */




.card-analitics-spheader {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.card-analitics-sp-header span:first-child {
    word-break: break-all;
    font-weight: 800;
    font-size: 25px;
    line-height: 135%;
    color: rgba(0, 0, 0, 0.66);
}

.card-analitics-sp-header span:last-child {
    font-weight: 700;
    font-size: 15px;
    line-height: 135%;
    color:#2ac126;
}

.temp {
    position: absolute;
    left: 25px;
    bottom: 12px;
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;
    color: #062d07;
}

.temp-scale {
    width: 80px;
    height: 36px;
    position: absolute;
    right: 25px;
    bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 9px;
}

.temp-scale span {
    font-weight: 700;
    font-size: 13px;
    line-height: 134.49%;
    color: rgba(87, 77, 51, 0.66);
}

    .icon-card {
    font-weight: 700;
    font-size: 75px;
    line-height: 135%;
    color: #2683C3;
}


.icon-img{
width: 90px;
height: 90px;
}

.image-preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.image-wrapper {
  width: 300px;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.file-input {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  font-size: 16px;
}

.file-input:hover {
  border-color: #999;
}


/* !Cards Category 2 */

/* Cards */

.card-analitics-sp-sm {
    width: 90%;
    height: 125px;
    position: relative;
    padding: 15px;
/* background: radial-gradient(178.94% 106.41% at 100% 100%, #bcddf6 10%, rgba(255, 255, 255, 0) 41.88%), #FFFFFF;  */

/* background: radial-gradient(178.94% 106.41% at 26.42% 106.41%, #bff6bc 0%, rgba(255, 255, 255, 0) 21.88%), #FFFFFF; */



    /* box-shadow: 0px 155px 62px rgba(0, 0, 0, 0.01), 0px 87px 52px rgba(0, 0, 0, 0.05), 0px 39px 39px rgba(0, 0, 0, 0.09), 0px 10px 21px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1); */


    border-radius: 23px;


    transition: all 0.8s cubic-bezier(0.15, 0.83, 0.66, 1);


    cursor: pointer;

    border: .5px solid #2bc12625;
}

.card-analitics-sp-sm:hover {
    transform: scale(1.05);
      border: .5px solid #2bc126c7;
}

/* 
.container {
    width: 250px;
    height: 250px;
    position: absolute;
    right: -35px;
    top: -50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0.7);
} */




.card-analitics-spheader {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.card-analitics-sp-header-sm span:first-child {
    word-break: break-all;
    font-weight: 800;
    font-size: 20px;
    line-height: 135%;
    color: rgba(0, 0, 0, 0.66);
  
}

.card-analitics-sp-header-sm span:last-child {
    font-weight: 700;
    font-size: 15px;
    line-height: 135%;
    color:#2ac126;
}

@media (max-width: 1024px) {
    .card-analitics-sp {
        width: 95%;
        height: 125px;
        position: relative;
        padding: 25px;
        /* box-shadow: 0px 155px 62px rgba(0, 0, 0, 0.01), 0px 87px 52px rgba(0, 0, 0, 0.05), 0px 39px 39px rgba(0, 0, 0, 0.09), 0px 10px 21px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1); */
        border-radius: 23px;
        transition: all 0.8s cubic-bezier(0.15, 0.83, 0.66, 1);
        cursor: pointer;
        border: 1px solid #2ac126;

        margin-top: 10px;
    }

    .card-analitics-sp-header span:first-child {
    word-break: break-all;
    font-weight: 800;
    font-size: 10px;
    line-height: 135%;
    color: rgba(0, 0, 0, 0.66);
}

    .card-value-total {
        position: absolute;
        left: 25px;
        bottom: 12px;
        font-weight: 700;
        font-size: 30px;
        line-height: 77px;
    }

    .icon-img {
    width: 60px;
    height: 60px;
}
}


@media (max-width: 992px) {
    .card-analitics-sp {
        width: 100%;
        height: 125px;
        position: relative;
        padding: 25px;
        border-radius: 23px;
        transition: all 0.8s cubic-bezier(0.15, 0.83, 0.66, 1);
        cursor: pointer;
        border: 1px solid #2ac126;

        margin-top: 10px;
    }

    .card-value-total {
        position: absolute;
        left: 25px;
        bottom: 12px;
        font-weight: 700;
        font-size: 30px;
        line-height: 77px;
    }
}


@media (max-width: 768px) {
    .card-analitics-sp {
        width: 100%;
        height: 125px;
        position: relative;
        padding: 25px;
        border-radius: 23px;
        transition: all 0.8s cubic-bezier(0.15, 0.83, 0.66, 1);
        cursor: pointer;
        border: 1px solid #2ac126;

        margin-top: 10px;
    }

    .card-analitics-sp-header span:first-child {
    word-break: break-all;
    font-weight: 800;
    font-size: 14px;
    line-height: 135%;
    color: rgba(0, 0, 0, 0.66);
}

    .card-value-total {
        position: absolute;
        left: 25px;
        bottom: 12px;
        font-weight: 700;
        font-size: 30px;
        line-height: 77px;
    }

    .icon-card {
    font-weight: 700;
    font-size: 45px;
    line-height: 135%;
    color: rgb(0, 0, 0);
}
}