  /*? New NavBar  */

  .app-header-container {
    width: 100%;
  }

  .app-header-primary {
    object-fit: fill;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    background-image: url("../img/backgrounds/header/bg-waves.png");
    background: #2b2f77;
    background: -webkit-radial-gradient(circle, #2b2f77 0%, #141852 50%, #070b34 100%);
    background: radial-gradient(circle, #2b2f77 0%, #141852 50%, #070b34 100%);
  }


  .app-container {
    margin: 0;
  }


  .menu,
  .menu-wrapper {
    display: flex;
    padding: 0;
    margin: 0;
    gap: 1rem;
    list-style: none;

  }

  .menu-lg-row>.menu-item {
    display: flex;
    align-items: center;
  }

  .menu-title {
    color: #181c32;
    font-size: 1.15rem;
    font-weight: 600;
    transition: color 0.5s ease-in-out;

  }

  .menu-title-responsive {
    color: #181c32;
    font-size: 20px;
    font-weight: 600;
  }

  .menu-subtitle-responsive {

    color: #181c32;
    font-size: 18px;
    font-weight: 700;
  }



  .menu-link {
    transition: color 0.5s ease-in-out;
    background-color: #f4f4f4;
    color: #181c32;
    padding: 13px 8px;
    margin: 20px 0;
    border-radius: 8px;
  }


  .menu-link:hover {
    transition: color 0.5s ease-in-out;
    background-color: #ff0000;
    color: #181c32;
    padding: 13px 8px;
    margin: 20px 0;
    border-radius: 8px;
  }

  .menu-link-icon {
    color: #181c32;
    font-size: 20px;
  }


  /* Dropdown Menu  */
  .dropdown-container {
    position: relative;
    display: inline-block;
    overflow: visible;

  }

  .dropdown-title {
    background-color: #3498db;
    color: white;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    width: 250px;

    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 20px;
    z-index: 1999;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .dropdown-container:hover .dropdown-content {
    display: block;
    opacity: 1;
    transform: translateY(0);

  }

  .dropdown-content a {
    color: black;
    text-decoration: none;
    display: block;
    padding: 5px;
  }

  .dropdown-content a:hover {
    background-color: #f1f1f1;
    padding: 5px;
    border-radius: 5px;
  }

  .dropdown-title-item {
    margin: 5px;
    color: #181c32;

  }

  .dropdown-title-item-active {
    margin: 5px;
    color: #181c32;
    background-color: #f4f4f4;
    border-radius: 5px;

  }

  .dropdown-description-item {
    margin-top: 2px;
    color: #868484;
    font-size: 14px;
    font-weight: 400;
  }

  /* User Card  */
  .area-profile {
    font-size: 18px;
    cursor: pointer;
    border: .5px solid #ffffff;
    background: #0a1445b4;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    padding: 5px;
  }

  .sidebar-main {
    height: 99vh;
    margin: 4px;
    display: none;
  }

  .header-nav-sp {
    /* position: fixed;
    top: 0;
    right: 0;
     */
    margin-top: 5px;
    position: sticky;
    top: 0;
    z-index: 1000;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 2%;
    transition: all .50s ease;
    -webkit-transition: all .50s ease;
    -moz-transition: all .50s ease;
    -ms-transition: all .50s ease;
    -o-transition: all .50s ease;
    border: var(--new-frame) solid 1px;
    background-color: var(--bg);
  }

  .header-nav-sp>.logo {
    display: flex;
    align-items: center;
  }


  /* *Pages Sections   */
  /* ?Navigation Top Bar  */

  /* NavBar Top */

  .navbar {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 0 20px;
    background: var(--blanco);
    color: #19191c;
  }

  .navbar-logo {
    display: flex;
    align-items: center;
    gap: 1px;
    /* width: 160px; */

  }

  .hrline {

    border: none;
    border-left: 1px solid hsla(200, 10%, 50%, 100);
    height: 95%;
    width: 10px;
  }


  .navbar-logo-icon {
    width: 80px;
    height: 45px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .navbar-logo-post {
    height: 35px;
  }



  .navbar-logo {
    display: flex;
    align-items: center;
  }

  .navbar-logo-text {
    color: #000;
    font-weight: 700;
    font-family: var(--fuente-main);
    display: block;
    font-size: 1.2rem;
  }


  /* Links Menu top  */
  .nabvar-sp {
    display: flex;
  }


  .nabvar-sp span {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin: 0px 0px;
    cursor: pointer;
    text-decoration: none;
    transition: all .50s ease;
    -webkit-transition: all .50s ease;
    -moz-transition: all .50s ease;
    -ms-transition: all .50s ease;
    -o-transition: all .50s ease;
  }

  .nav-link {
    display: block;
    padding: .5px .7px;
    color: #909090 !important;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    -moz-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    -ms-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    -o-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    color: #ffffff !important;
    background-color: #000 !important;
  }

  .nav-underline .nav-link.active,
  .nav-underline .show>.nav-link {
    font-weight: 700;
    color: #000 !important;
    ;
    border-bottom-color: currentcolor;
  }

  /* Links  */

  .nabvar-sp a {
    font-weight: normal;
    text-decoration: none;
  }

  .nabvar-sp a:hover {
    cursor: pointer;

  }

  .no-active-link {
    color: rgb(108, 108, 108);
    font-weight: 500;
  }

  .active-link {
    color: var(--primario);
    font-weight: 800;
  }

  .close-login {
    color: #000;
    cursor: pointer;
    transition: all ease-in-out .2s;
  }

  .close-login:hover {
    color: red;
    cursor: pointer;
    border: 1px solid red;
    background-color: rgba(255, 70, 70, 0.199);

  }

  .conrainer-profile-img-sidebar {
    position: relative;
    width: 45px;
    height: 45px;
    background-color: #ffffff;

  }

  .profile-img-sidebar {
    width: 100%;
    height: 45px;
    object-fit: cover;
  }

  .sp-grid {
    display: grid;
    gap: 2;
    grid-template-columns: 200px 1fr;
  }

  .fixed-column {
    position: fixed;
    width: 200px;
  }

  .other-column {
    margin-left: 200px;
  }


  .list-navigation-menu {
    display: inline;

  }

  .icon-menu-sp {
    display: none;
  }

  .border-menu-start {
    border: .5px solid #dee2e6;
  }




  .text-subtitle-menu {
    display: none;
  }

  .menu-wrapper {
    display: block;
    margin-top: 1px;
  }

  .menu-bar {


    display: none;
    /* Oculto por defecto */
  }

  .menu-link-icon {
    display: inline-block;
    /* Oculto por defecto */
  }

  @media (max-width: 912px) {
    .app-header-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
    }

    .menu-bar {

      display: block;
      /* Oculto por defecto */
    }

    .menu-link-icon {
      display: inline-block;
      /* Se muestra solo en dispositivos menores a 912px */
    }
  }

  .menu-link-icon-responsive {
    display: block;
    background: none;
    border: none;
    font-size: 35px;
    cursor: pointer;
  }

  @media (max-width: 912px) {
    .menu-link-icon-responsive {
      display: block;
    }

    .mobile-menu {
      position: absolute;
      top: 55px;
      left: 10px;
      width: 90%;
      background: white;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      padding: 10px;
      border-radius: 15px;
    }

    .mobile-menu ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .mobile-menu li {
      padding: 10px;
      border-bottom: 1px solid #ddd;
    }

    .mobile-menu li a {
      text-decoration: none;
      color: black;
      display: block;
    }
  }

  .offcanvas-menu {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background: #fff;
    transition: left 0.3s ease-in-out;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  }

  .offcanvas-menu.show {
    left: 0;
  }

  .offcanvas-header.offcanvas-menu {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #f8f9fa;
  }

  .offcanvas-title.offcanvas-menu {
    font-size: 18px;
    color: #007bff;
  }

  .btn-close.offcanvas-menu {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }

  .offcanvas-body.offcanvas-menu {
    padding: 15px;
  }

  .offcanvas-backdrop.offcanvas-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  @media (max-width: 912px) {

    .menu-wrapper {
      display: none;

    }

    .icon-menu-sp {
      padding: 4px 6px;
    }

    .sp-grid {
      display: flex;
      flex-direction: column;
      /* grid-template-columns: 1fr; */
    }

    .fixed-column {
      position: fixed;
      z-index: 199;
      width: 100%;
      top: 0;
    }

    .other-column {
      margin-top: 60px;
      display: block;
    }

    .sidebar-main {
      height: 100%;
      margin: 4px;
      display: none;
    }

    .icon-menu-sp {
      display: block;
    }

    .navbar-logo-text {
      display: none;
    }

    .list-menu-main-sp {
      display: none;
    }

    .list-menu-main-sp-open {
      display: inline;
    }

    .border-menu-start {
      border: 0px solid #dee2e6;
    }

    .border-menu-top {
      border: .5px solid #dee2e6;
    }
  }

  @media (max-width: 768px) {
    .app-header-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
    }

    .menu-wrapper {
      display: none;

    }

    .navbar-logo-icon {
      height: 50px;
      width: 50px;
      object-fit: contain;
    }

    .nabvar-sp {
      position: absolute;
      top: 80%;
      right: 2%;
      width: 270px;
      height: auto;
      background-color: var(--blanco);
      flex-direction: column;
      justify-content: flex-start;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      transition: all .50s ease;
      -webkit-transition: all .50s ease;
      -moz-transition: all .50s ease;
      -ms-transition: all .50s ease;
      -o-transition: all .50s ease;
      border: #c3c3c3 solid 1.5px;
    }

    .icon-menu-sp {
      padding: 4px 6px;
    }

    .sp-grid {
      display: flex;
      flex-direction: column;
      /* grid-template-columns: 1fr; */
    }

    .fixed-column {
      position: fixed;
      z-index: 199;
      width: 100%;
      top: 0;
    }

    .other-column {
      margin-top: 60px;
      display: block;
    }

    .sidebar-main {
      height: 100%;
      margin: 4px;
      display: none;
      border: .5px solid #dee2e6;

    }

    .icon-menu-sp {
      display: block;
    }

    .navbar-logo-text {
      display: none;
    }

    .sidebar-main-open {
      height: 100%;
      margin: 4px;
      display: inline;

      border: .5px solid #dee2e6;
    }

    .list-menu-main-sp {
      display: none;

    }

    .list-menu-main-sp-open {
      display: block;

    }


    .area-profile {
      border: .5px solid #ffffff;
    }


    .border-menu-start {
      border: 0px solid #dee2e6;
    }

    .border-menu-top {
      border: .5px solid #dee2e6;
    }

    .text-subtitle-menu {

      display: inline;
    }

    .slide-in-blurred-top {
      animation: slide-in-blurred-top .6s cubic-bezier(.23, 1.000, .32, 1.000) both
    }




    @keyframes slide-in-blurred-top {
      0% {
        transform: translateY(-1000px) scaleY(2.5) scaleX(.2);
        transform-origin: 50% 0;
        filter: blur(40px);
        opacity: 0
      }

      100% {
        transform: translateY(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1
      }
    }

  }

  @media (max-width: 430px) {
    .app-header-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
    }

    .menu-wrapper {
      display: none;

    }
  }


  @media (max-width: 367px) {
    .app-header-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
    }

    .menu-wrapper {
      display: none;

    }
  }