.banner-reports {
    background-color: #ffffff;
    border: 1px solid #ffffff;
    /* position: sticky;
    top: 0;
    z-index: 99; */
}

.banner-reports-title {
    color: #000000;
    font-size: 25px;
    font-weight: 700;
}

.banner-reports-subtitle {
    display: inline;
    color: #000000;
    font-size: 20px;
    font-weight: 500;
}

.banner-reports-description {
    display: inline;
    color: #969696;
    font-size: 18px;
    font-weight: 500;

}

@media (max-width: 992px) {
    .banner-reports-title {
        color: #000000;
        font-size: 20px !important;
        font-weight: 700;
    }

    .banner-reports-description {
    display: inline;
    color: #bdbdbd;
    font-size: 15px;
    font-weight: 500;
}

}


@media (max-width: 375px) {

    .banner-reports-title {
        color: #000000;
        font-size: 19px !important;
        font-weight: 700;
        text-align: center;
    }

    .banner-reports-description {
        display: inline;
        color: #6d6d6d;
        font-size: 15px;
        font-weight: 500;

    }
}