.tooltip-container {
    position: relative;
    display: inline-block;
    visibility: visible;
    text-wrap: initial;
    cursor: pointer;
}

.tooltip-text {
    visibility: hidden;
    width: 150px;
    background-color: #333;
    color: #fff;
    text-align: left;
    padding: 8px;
    border-radius: 5px;
    position: absolute;
    bottom: 125%;
    left: 20px;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    font-size: 14px;
    white-space: normal;
    /* Permite múltiples líneas */
    word-wrap: break-word;
    /* Rompe palabras largas */
}

.tooltip-container:hover .tooltip-text,
.tooltip-container:focus-within .tooltip-text {
    visibility: visible;
    opacity: 1;
}


@media (max-width: 912px) {


    .tooltip-text {
        visibility: hidden;
        min-width: 90px;
        max-width: 450px;
        background-color: #333;
        color: #fff;
        text-align: left;
        padding: 8px;
        border-radius: 5px;
        position: absolute;
        bottom: 125%;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity 0.3sease-in-out;
        font-size: 14px;
        white-space: normal;
        word-wrap: break-word;
    }
}