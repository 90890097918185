 /* Roots */

 /* End Roots */


 /*Vending Menu  */


 .vending-input-qr {
   margin: -30rem;
 }

 .pre-account-menu {
   width: 100%;
   height: 100vh;
   overflow-y: scroll;
   scroll-behavior: smooth;
   /* background-image: url('./assets/img/pre-bg.svg'); */
   background-size: cover;
   background-repeat: no-repeat;
   background-attachment: fixed;
 }

 .imgOcr {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 10%;
}





 .input-upc {
   position: fixed;
   top: -20rem;
 }

 .vending-top {
   background-color: var(--blanco);
   width: 640px;
   /* margin: 0 auto; */
   position: fixed;
   top: 0;
   z-index: 1030;
 }

 .vending-bottom {
   background-color: var(--blanco);
   border: 1px solid var(--blanco);
   border-radius: 10px;
   position: fixed;
   right: 5px;
   bottom: 0;
   z-index: 1030;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
 }

 .vending-item {
   /* background-image: url('./assets/img/bg-menu.svg'); */
   margin-top: 30px;
   height: 100%;
   /* position: absolute;
   z-index: 2; */
 }

 .imgEditModal {
   width: 70%;
   height: 300px;
   border-radius: 15px;
   object-fit: cover;
   cursor: pointer;

 }

 .imgitemsTable {
   width: 80px;
   height: 80px;
   object-fit: cover;
 }

 .vending-row {
   display: flex;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-items: flex-start;
   align-content: flex-start;
   width: 100%;
   height: 100%;
   margin-top: 37px;
   overflow: auto;
   flex-direction: row;
   position: absolute;
   z-index: 2;
 }

 .vending-row div {
   margin: 0 auto;
   /* width: 255px; */
   height: 207px;
   background-color: transparent;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   /* border: 1px solid var(--negro);     */
 }

 .vending-img-product {
   width: 120px;
   height: 135px;
   object-fit: cover;
 }


 .vendindg-bg-stand {
   margin-top: 120px;
   height: 150px;
   width: 100%;
   z-index: 1;
   background-color: transparent;

 }

 .vending-menu-products {
   padding-bottom: 5rem;
   margin-top: 29px;
   width: 100%;
   height: 100%;
   overflow-y: scroll;
   background-color: transparent;

 }

 /* Content Modal Product */
 /* .img-product-menu{
  width: 140px;
  height: 140px;

} */
 .title-product-menu {
   font-size: 17px;
   font-weight: 800;
   text-align: center;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   width: 155px;
 }

 .title-price-menu {
   font-size: 25px;
 }


 .card-body-product {
   background-color: transparent;
 }

 .vending-stand {
   margin-top: 0px;
 }

 .grid-row-vending {
   height: 70px;
   background-image: url('./assets/img//vending/stand.png');
 }

 /* lIST CATEGORIES  */
 .categorie-list-menu {
   margin: 20px;
   padding: 2px 0px;
   position: relative;
 }

 .categorie-list-menu::-webkit-scrollbar {
   width: 2px !important;
 }

 .categorie-list-menu ul {
   list-style: none;
   margin: 0;
   padding: 0;
 }

 .categorie-list-menu li {

   margin: 30px 0px;
 }

 .categorie-list-menu label {
   width: 0px;
 }

 /* Buttons */
 .btn-vending-product {
   font-size: 18px;
   font-weight: 700;
   background-color: var(--primary-vending);
   border: 1px solid #ccc;
   padding: 30px 70px;
 }

 .btn-left-border {
   border-radius: 20px 0 0 20px;
   -webkit-border-radius: 20px 0 0 20px;
   -moz-border-radius: 20px 0 0 20px;
   -ms-border-radius: 20px 0 0 20px;
   -o-border-radius: 20px 0 0 20px;
 }

 .btn-right-border {
   border-radius: 0 20px 20px 0;
   -webkit-border-radius: 0 20px 20px 0;
   -moz-border-radius: 0 20px 20px 0;
   -ms-border-radius: 0 20px 20px 0;
   -o-border-radius: 0 20px 20px 0;
 }


 .btn-outline-primary-vending {
   font-weight: 400;
   color: var(--primary-vending);
   background-color: var(--blanco);
   border: 1px solid var(--primary-vending);

 }


 /* Icons */
 .icon-button {
   font-size: 2rem;
 }

 /* Icons */
 .icon-button-setting-vending {
   font-size: 3rem;
 }

 .icon-button-params {
   font-size: 30px;
 }

 /* Empty Cart */
 .empty-cart {
   margin-top: 100px;
 }

 .empty-img {
   width: 80px;
   height: 80px;
 }

 .empty-title,
 .empty-msg {
   font-size: 25px;
 }


 @media (max-width: 767px) {
   .vending {
     width: 100%;
   }

   .vending-menu {
     padding-bottom: 5rem;
     margin-top: 4rem;
     width: 100%;
     max-height: 100%;
     overflow-y: scroll;
     z-index: -99;
   }

   .img-modal-product-stock {
     width: 10rem;
     height: 10rem;
     object-fit: cover;
     margin: 2rem 1rem 5rem 1rem;
   }

   .background-image {
     width: 100%;
     top: 18rem;
     left: 0;
     z-index: 1;
     position: absolute;
   }

   .title-count-stock {
     font-size: 20px;
   }


   .number-counter {
     display: flex;
     align-items: center;
     justify-content: center;
   }

   .number-counter p {
     font-size: 22px;
     font-weight: 700;
     margin: 0 1rem;
   }

   .number-counter button {
     font-weight: 800;
     font-size: 20px;
     width: 3rem;
     height: 3rem;
     border-radius: 50%;
     border: none;
     background-color: var(--primary-vending);
     color: #fff;
     cursor: pointer;
     -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
     -ms-border-radius: 50%;
     -o-border-radius: 50%;
   }

   .number-counter button:hover {
     background-color: #00725f;
     width: 3rem;
     height: 3rem;
   }


   .plus,
   .minus {
     position: relative;
   }

   .plus:before,
   .plus:after {
     content: "";
     position: absolute;
     top: 50%;
     left: 50%;
     width: 5px;
     height: 15px;
     background-color: white;
   }

   .plus:before {
     transform: translate(-50%, -50%);
   }

   .plus:after {
     transform: translate(-50%, -50%) rotate(90deg);
   }


   .minus:before,
   .minus:after {
     content: "";
     position: absolute;
     top: 50%;
     left: 50%;
     width: 22px;
     height: 6px;
     background-color: var(--blanco);
   }

   .minus:before {
     transform: translate(-50%, -50%);
   }

   .minus:after {
     transform: translate(-50%, -50%);
     -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
     -ms-transform: translate(-50%, -50%);
     -o-transform: translate(-50%, -50%);
   }

   .btn-menu-vending {
     margin: 5px 0px;
     font-size: 15px;
     font-weight: 700;
     background-color: var(--primary-vending);
     border: 1px solid #ccc;
     padding: 6px 20px;
     border: 1px solid #000;
     border-radius: 30px;
     -webkit-border-radius: 30px;
     -moz-border-radius: 30px;
     -ms-border-radius: 30px;
     -o-border-radius: 30px;
   }

   /* Buttons */
   .btn-vending-product {
     font-size: 18px;
     font-weight: 700;
     background-color: var(--primary-vending);
     border: 1px solid #ccc;
     padding: 10px 10px;
   }



 .imgEditModal {
   width: 99%;
   height: 300px;
   border-radius: 15px;
   object-fit: cover;
   cursor: pointer;

 }
 }

 @media (max-width: 820px) {

   .vending-menu {
     padding-bottom: 5rem;
     margin-top: 4rem;
     width: 100%;
     max-height: 100%;
     overflow-y: scroll;
     z-index: -99;
   }

   .img-modal-product-stock {
     width: 10rem;
     height: 10rem;
     object-fit: cover;
     margin: 2rem 1rem 5rem 1rem;
   }

   .background-image {
     width: 100%;
     top: 18rem;
     left: 0;
     z-index: 1;
     position: absolute;
   }

   .title-count-stock {
     font-size: 20px;
   }



   .number-counter {
     display: flex;
     align-items: center;
     justify-content: center;
   }

   .number-counter p {
     font-size: 22px;
     font-weight: 700;
     margin: 0 1rem;
   }

   .number-counter button {
     font-weight: 800;
     font-size: 20px;
     width: 3rem;
     height: 3rem;
     border-radius: 50%;
     border: none;
     background-color: var(--primary-vending);
     color: #fff;
     cursor: pointer;
     -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
     -ms-border-radius: 50%;
     -o-border-radius: 50%;
   }

   .number-counter button:hover {
     background-color: #00725f;
     width: 3rem;
     height: 3rem;
   }


   .plus,
   .minus {
     position: relative;
   }

   .plus:before,
   .plus:after {
     content: "";
     position: absolute;
     top: 50%;
     left: 50%;
     width: 5px;
     height: 15px;
     background-color: white;
   }

   .plus:before {
     transform: translate(-50%, -50%);
   }

   .plus:after {
     transform: translate(-50%, -50%) rotate(90deg);
   }


   .minus:before,
   .minus:after {
     content: "";
     position: absolute;
     top: 50%;
     left: 50%;
     width: 22px;
     height: 6px;
     background-color: var(--blanco);
   }

   .minus:before {
     transform: translate(-50%, -50%);
   }

   .minus:after {
     transform: translate(-50%, -50%);
     -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
     -ms-transform: translate(-50%, -50%);
     -o-transform: translate(-50%, -50%);
   }

   .btn-menu-vending {
     margin: 5px 0px;
     font-size: 15px;
     font-weight: 700;
     background-color: var(--primary-vending);
     border: 1px solid #ccc;
     padding: 6px 20px;
     border: 1px solid #000;
     border-radius: 30px;
     -webkit-border-radius: 30px;
     -moz-border-radius: 30px;
     -ms-border-radius: 30px;
     -o-border-radius: 30px;
   }

   /* Buttons */
   .btn-vending-product {
     font-size: 18px;
     font-weight: 700;
     background-color: var(--primary-vending);
     border: 1px solid #ccc;
     padding: 10px 10px;
   }

 }


 @media (max-width: 1024px) {
   .vending-menu {
     padding-bottom: 5rem;
     margin-top: 4rem;
     width: 100%;
     max-height: 100%;
     overflow-y: scroll;
     z-index: -99;
   }

   .foreground-image {
     z-index: 2;
   }

   .img-modal-product-stock {
     width: 10rem;
     height: 10rem;
     object-fit: cover;
     margin: 2rem 1rem 5rem 1rem;
   }

   .background-image {
     width: 100%;
     top: 13rem;
     left: 0;
     z-index: 1;
     position: absolute;
   }

   .title-count-stock {
     font-size: 20px;
   }


   .number-counter {
     display: flex;
     align-items: center;
     justify-content: center;
   }

   .number-counter p {
     font-size: 22px;
     font-weight: 700;
     margin: 0 1rem;
   }

   .number-counter button {
     font-weight: 800;
     font-size: 20px;
     width: 3rem;
     height: 3rem;
     border-radius: 50%;
     border: none;
     background-color: var(--primary-vending);
     color: #fff;
     cursor: pointer;
     -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
     -ms-border-radius: 50%;
     -o-border-radius: 50%;
   }

   .number-counter button:hover {
     background-color: #00725f;
     width: 3rem;
     height: 3rem;
   }


   .plus,
   .minus {
     position: relative;
   }

   .plus:before,
   .plus:after {
     content: "";
     position: absolute;
     top: 50%;
     left: 50%;
     width: 5px;
     height: 15px;
     background-color: white;
   }

   .plus:before {
     transform: translate(-50%, -50%);
   }

   .plus:after {
     transform: translate(-50%, -50%) rotate(90deg);
   }


   .minus:before,
   .minus:after {
     content: "";
     position: absolute;
     top: 50%;
     left: 50%;
     width: 22px;
     height: 6px;
     background-color: var(--blanco);
   }

   .minus:before {
     transform: translate(-50%, -50%);
   }

   .minus:after {
     transform: translate(-50%, -50%);
     -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
     -ms-transform: translate(-50%, -50%);
     -o-transform: translate(-50%, -50%);
   }

   .btn-menu-vending {
     margin: 5px 0px;
     font-size: 15px;
     font-weight: 700;
     background-color: var(--primary-vending);
     border: 1px solid #ccc;
     padding: 6px 20px;
     border: 1px solid #000;
     border-radius: 30px;
     -webkit-border-radius: 30px;
     -moz-border-radius: 30px;
     -ms-border-radius: 30px;
     -o-border-radius: 30px;
   }

   /* Buttons */
   .btn-vending-product {
     font-size: 18px;
     font-weight: 700;
     background-color: var(--primary-vending);
     border: 1px solid #ccc;
     padding: 10px 10px;
   }

 }


 /* Desing Frame dimensions 1080x1920 Vendin Main  */
 @media screen and (min-width: 1080px) and (min-height: 1920px) {
   .vending {
     width: 100%;
   }

   .vending-menu {
     width: 100%;
     height: 100%;
     overflow-y: scroll;
     scroll-behavior: smooth;
   }

   .vending-top {
     width: 100%;
     position: fixed;
     top: 0;
     right: 0;
     left: 0;
     z-index: 1030;
   }

   .vending-bottom {
     position: fixed;
     right: 0;
     bottom: 0;
     left: 0;
     z-index: 1030;
   }

   .vending-item {
     margin-top: 30px;
     height: 100%;
     background-color: transparent;
     /* position: absolute;
     z-index: 2; */
   }

   .vending-row {
     display: flex;
     flex-wrap: nowrap;
     justify-content: flex-start;
     align-items: flex-start;
     align-content: flex-start;
     width: 100%;
     height: 100%;
     margin-top: 53px;
     overflow: auto;
     flex-direction: row;
     position: absolute;
     z-index: 2;
   }

   .vending-row div {
     margin: 0 auto;
     width: 255px;
     height: 390px;
     background-color: transparent;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     /* border: 1px solid var(--negro);     */
   }

   .vending-img-product {
     width: 220px;
     height: 235px;
     object-fit: cover;
   }


   .title-product-menu {
     font-size: 2.3rem;
     font-weight: 600;
     text-align: center;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     width: 100%;
   }

   .title-price-menu {
     font-size: 2rem;
   }

   .vendindg-bg-stand {
     margin-top: 184px;
     height: 300px;
     width: 100%;
     z-index: 1;
     background-color: transparent;
   }



   .vending-menu-products {
     padding-bottom: 5rem;
     margin-top: 11rem;
     width: 100%;
     height: 100%;
     overflow-y: scroll;
     /* position: absolute;
   top: 0;
   left: 0;
   z-index: 2; */
   }

   .vending-menu-stock {
     margin: 1rem 1rem 0;
     max-height: 100%;
     overflow-y: scroll;
     z-index: -99;
   }

   .vending-content {
     background-color: transparent;
   }

   .vendindg-title>h2 {
     font-size: 3.7rem;
     margin: 40px 20px;
     color: #071437;
   }

   .vendindg-message>h2 {
     font-size: 2.7rem;
     color: #071437;
   }

   .vendindg-img {
     margin: 0px 0 0 0;
   }

   .vending-qr {
     width: 80%;
     height: 100%;
   }

   .vending-button {
     margin-top: 0rem;
   }

   .button-sub-title {
     font-size: 3rem;
     margin: 0 0;
   }




   /* buttons  */


   .btn-vending,
   .btn-vending-succes,
   .btn-vending-secondary,
   .btn-vending-danger {
     font-size: 3rem;
     font-weight: 700;
     padding: 20px 40px;
     margin: 2rem 0;
     color: var(--blanco);

   }



   .btn-vending-success {
     background-color: #00AA00;
   }

   .btn-vending-secondary {
     background-color: #dcdcdc;
   }

   .btn-vending-danger {
     background-color: #AA0000;
   }

   /* KeyPad */
   /* keyPad Numeric  */
   .section-keyboard {
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     border-radius: 30px;
     -webkit-border-radius: 30px;
     -moz-border-radius: 30px;
     -ms-border-radius: 30px;
     -o-border-radius: 30px;
     padding: 10px;
   }

   .keypad-input {
     color: var(--primary-vending);
     margin: 2.5rem 0;
     padding: 20px 0;
     font-size: 5.5rem;
     font-weight: 700;
     text-align: center;
     border: 2px solid rgb(226, 226, 226);
     letter-spacing: 20px;
   }

   .keyboard-main {
     background-color: transparent;
   }

   .keyboard-pad {
     margin: 0.5rem 0;
   }

   .keyboard-pad>.num,
   .delete {
     color: #000;
     font-weight: 800;
     text-align: center;
     font-size: 4rem;
     border-radius: 10px;
     -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
     -ms-border-radius: 10px;
     -o-border-radius: 10px;
     padding: 45px 0px;
     width: calc(33.333% - 8px);
     margin: 0 4px 8px;
     cursor: pointer;
     transition: all 0.2s linear;
     -webkit-transition: all 0.2s linear;
     -moz-transition: all 0.2s linear;
     -ms-transition: all 0.2s linear;
     -o-transition: all 0.2s linear;
     border: 2px solid rgb(226, 226, 226);
     box-shadow: 2px 2px 0 rgb(0, 0, 0, .2);
     inset: 4px -4px 0px #d4c69d;
     inset: -4px 4px 0px #beb28d;
   }

   .key {
     width: 5rem;
     font-size: 3rem;
     margin: 5px;
   }

   .keySpaceDelete {
     font-size: 3rem;
     margin: 5px;
   }

   .keypadPrimary-input {
     color: var(--primary-vending);
     margin: 2.5rem 0;
     padding: 20px 0;
     font-size: 2.5rem;
     font-weight: 700;
     text-align: center;
     border: 2px solid rgb(226, 226, 226);
     letter-spacing: 5px;
   }


   .keyboard-pad>.num:hover {
     color: var(--primary-vending);
     background-color: #bae4dd37;
   }

   /* * keyboard */
   .keyboard-step {
     padding: 1rem;
     /* background-color: #bae4ddd9; */
   }

   .keyboard-title {
     text-align: center;
     font-weight: 700;
     font-size: 4rem;
   }

   .keyboard-steps-label {
     font-size: 1.7rem;
     font-weight: 700;
   }

   .keyboard-steps-number {
     font-size: 3rem;
     font-weight: 600;
   }

   .keyboard-steps-number-active {
     color: var(--primary-vending);
     font-size: 3rem;
     font-weight: 600;
   }

   /* End KeyBoard */

   .menu-vending-title>h1 {
     font-size: 4.5rem;
     font-weight: 800;
   }

   .btn-menu-vending {
     font-size: 2.5rem;
     font-weight: 700;
     background-color: var(--primary-vending);
     border: 1px solid #ccc;
     padding: 15px 30px;
     border: 1px solid #000;
     border-radius: 30px;
     -webkit-border-radius: 30px;
     -moz-border-radius: 30px;
     -ms-border-radius: 30px;
     -o-border-radius: 30px;
   }

   .text-title-help {
     color: #071437;
   }

   .btn-vending-product {
     font-size: 3rem;
     font-weight: 700;
     background-color: var(--primary-vending);
     border: 1px solid #ccc;
     padding: 30px 70px;
   }

   .btn-other {
     font-size: 3rem;
     font-weight: 700;
     color: #1263d3;
     padding: 30px 70px;
   }

   .btn-other:hover {
     font-size: 3rem;
     font-weight: 800;
     color: #227ed3;
     background-color: #5fa3e64a;
     padding: 30px 70px;
   }


   .btn-left-border {
     border-radius: 60px 0 0 60px;
     -webkit-border-radius: 60px 0 0 60px;
     -moz-border-radius: 60px 0 0 60px;
     -ms-border-radius: 60px 0 0 60px;
     -o-border-radius: 60px 0 0 60px;
   }

   .btn-right-border {
     border-radius: 0 60px 60px 0;
   }

   .icon-button {
     font-size: 7rem;
   }

   .icon-button-params {
     font-size: 4rem;
   }

   .title-modal-categories,
   .title-modal-product {
     font-size: 4.3rem;
     font-weight: 600;
   }

   .radio-group label {
     display: block;
     margin-bottom: 10px;
   }

   /* lIST CATEGORIES  */
   .categorie-list-menu {
     margin: 20px;
     padding: 2px 0px;
     position: relative;
   }

   .categorie-list-menu::-webkit-scrollbar {
     width: 2px !important;
   }

   .categorie-list-menu ul {
     list-style: none;
     margin: 0;
     padding: 0;
   }

   .categorie-list-menu li {

     margin: 30px 0px;
   }

   .categorie-list-menu label {
     width: 0px;
   }

   /* .button-container {
     display: inline-block;
     vertical-align: top;
   } */

   /* .button-container img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  } */
   /* 
   .button-container label {
     width: 100%;
      display: inline-block; 
     vertical-align: top;
     /* white-space: nowrap; 
     font-size: 3.5rem;
     padding: 1rem 0;
   } */

   .title-card-product {
     font-size: 14px;
   }

   .btn-close-categorie {
     font-size: 5rem;
     font-weight: 500;
   }

   .img-modal-product {
     width: 45rem;
     height: 45rem;
     object-fit: fill;
     margin: 0rem 0;
   }

   .img-modal-product-stock {
     width: 38rem;
     height: 38rem;
     object-fit: cover;
     margin: 7rem 0;
   }

   .modal-product {
     font-size: 5rem;
     font-weight: 500;
   }

   .modal-title-product {
     font-size: 5.5rem;
     font-weight: 800;
   }

   .modal-price-product {
     font-size: 6rem;
     font-weight: 600;
     margin: 0rem 0;
   }

   .modal-price-product-before {
     color: red;
     font-size: 4rem;
     font-weight: 600;
     margin: 4rem 0rem 0rem 0rem;

   }

   .number-counter {
     display: flex;
     align-items: center;
     justify-content: center;
   }

   .number-counter p {
     font-size: 10rem;
     font-weight: 700;
     margin: 0 3rem;
   }

   .number-counter button {
     font-weight: 800;
     font-size: 9rem;
     width: 11rem;
     height: 11rem;
     border-radius: 50%;
     border: none;
     background-color: var(--primary-vending);
     color: #fff;
     cursor: pointer;
     -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
     -ms-border-radius: 50%;
     -o-border-radius: 50%;
   }

   .number-counter button:hover {
     background-color: #00725f;
     width: 11rem;
     height: 11rem;
   }

   .title-modal-cart {
     font-size: 5rem;

   }

   .title-modal-pay,
   .title-modal-stock {
     font-size: 3rem;
   }

   .title-modal-cart,
   .title-modal-pay,
   .title-modal-stock {
     font-weight: 800;
   }

   .title-small-pay {
     font-size: 2rem;
     font-weight: 700;
   }

   .text_cart_vending {
     font-size: 2rem;

   }

   .totals-labels>p {
     font-size: 3rem;
     font-weight: 700;
   }

   .img-pay-group:hover {
     background-color: #0025B8;
   }

   .img-product-cart {
     width: 12rem;
     height: 12rem;
   }

   .title-product-shop-cart,
   .title-product-count,
   .title-product-price {
     font-size: 2.7rem;
   }

   .title-product-shop-cart {
     font-weight: 700;
   }

   .title-product-price {
     color: var(--primario);
     font-weight: 700;
   }

   .btn-vending-plack {
     font-size: 3rem;
     font-weight: 700;
     padding: 40px 35px;
     margin: 2rem 0;
   }

   .btn-count {
     color: var(--blanco);
     font-size: 3rem;
     font-weight: 700;
     padding: 5px 25px;
     margin: 2rem 0;
     border: 1px solid var(--primary-vending);
     background-color: var(--primary-vending);
   }

   /* Categories Menu  */

   .btn-check {
     background-color: var(--blanco);
   }

   .btn-outline-primary-venidng {
     font-weight: 400;
     color: var(--primary-vending);
     background-color: var(--blanco);
     border: 4px solid var(--primary-vending);
     border-radius: 20px;
     -webkit-border-radius: 20px;
     -moz-border-radius: 20px;
     -ms-border-radius: 20px;
     -o-border-radius: 20px;
   }



   .close-x {
     font-size: 2.5rem;
   }

   .label-wrap {
     overflow-wrap: break-word;
     word-wrap: break-word;
     word-break: break-word;
   }

   .placeholder {
     background-color: #f2f2f2;
     padding: 10px;
     border-radius: 4px;
   }

   .placeholder-line {
     height: 10px;
     background-color: #e0e0e0;
     margin-bottom: 5px;
   }

   .branding-mark {
     font-size: 1.5rem;
   }

   .round-button {
     display: inline-block;
     width: 10rem;
     height: 10rem;
     border-radius: 50%;
     background-color: var(--primary-vending);
     color: white;
     font-size: 24px;
     text-align: center;
     line-height: 50px;
   }

   .round-button:hover {
     display: inline-block;
     width: 10rem;
     height: 10rem;
     border-radius: 50%;
     background-color: #2683C3;
     color: white;
     font-size: 24px;
     text-align: center;
     line-height: 50px;
   }

   .plus,
   .minus {
     position: relative;
   }

   .plus:before,
   .plus:after {
     content: "";
     position: absolute;
     top: 50%;
     left: 50%;
     width: 5rem;
     height: 15px;
     background-color: white;
   }

   .plus:before {
     transform: translate(-50%, -50%);
   }

   .plus:after {
     transform: translate(-50%, -50%) rotate(90deg);
   }


   .minus:before,
   .minus:after {
     content: "";
     position: absolute;
     top: 50%;
     left: 50%;
     width: 5rem;
     height: 15px;
     background-color: var(--blanco);
   }

   .minus:before {
     transform: translate(-50%, -50%);
   }

   .minus:after {
     transform: translate(-50%, -50%);
     -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
     -ms-transform: translate(-50%, -50%);
     -o-transform: translate(-50%, -50%);
   }

   .product-stand {
     width: 100%;
     height: 845px;
     margin: 50px 0px 75px;
     position: relative;
   }

   .product-stand-stock {
     width: 100%;
     height: 842px;
     position: relative;
   }

   .empty-stock {
     border-radius: 10px;
     border: 20px dashed var(--gris);
     margin: 200px 0;
     padding: 270px 10px;
     width: 650px;
     -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
     -ms-border-radius: 10px;
     -o-border-radius: 10px;
   }

   .empty-stock>i {
     font-size: 10rem;
   }

   .empty-stock>p {
     font-weight: 700;
     font-size: 3rem;
   }

   .title-count-stock {
     font-size: 3em;
   }

   .background-image {
     width: 100%;
     bottom: 0;
     left: 0;
     z-index: 1;
     position: absolute;
   }

   .foreground-image {
     z-index: 2;
     /* Ponerla adelante */
   }

   .empty-cart {
     margin-top: 20rem;
   }

   .empty-img {
     width: 150px;
     height: 150px;
   }

   .empty-title,
   .empty-msg {
     font-size: 45px;
   }

   .met-pay {
     width: 100%;
     height: 500px;
     margin: 0px 0;
   }


   .menu-vending-temp>h3,
   .menu-vending-time>h3 {
     font-size: 3rem;
     font-weight: 800;
   }

   .round-button-count {
     width: 70px;
     height: 70px;
     border-radius: 50%;
     background-color: var(--primary-vending);
     border: none;
     color: white;
     font-size: 35px;
     font-weight: 600;
     position: relative;
     overflow: hidden;
   }

   .button-number {
     padding: 20px;
     font-size: 5.5rem;
   }

   .icon-trash {
     font-size: 3rem;
   }

   .name-product-cart {
     width: 370px;
   }

   .count-menu {
     bottom: 40px;
     left: 45px;
   }

   .stock-container {
     display: flex;
     flex-wrap: wrap;
     justify-content: flex-start;
     align-items: flex-start;
     align-content: flex-start;
     width: 100%;
     background-color: transparent;
     /* height: 80vh; */
     overflow: auto;
     flex-direction: row-reverse;
   }

   .stock-container div {
     width: 90px;
     height: 280px;
   }

   .name-stock-item {
     font-size: 11px;

   }

   .img-pay-meth {
     width: 200px;
     height: 200px;
   }

   .name-product-cart-end {
     font-size: 3rem;

   }

   .met-paymens-buttons {
     margin-top: 260px;
   }

   .section-thanks {
     background-image: url('./components/img/backpad.png');
     background-size: cover;
     position: relative;
   }

   .mensaje {
     width: 100%;
     color: #000;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     background-color: rgba(255, 255, 255, 0.8);
     /*Colordefondodelmensajecontransparencia*/
     padding: 10px 20px;
     font-size: 24px;
     font-weight: bold;
   }



   .buttons-steps {
     position: absolute;
     z-index: 99;
     top: 50%;
   }

 }

 .input-wrapper {
   position: relative;
   width: 271px;
 }

 .input {
   box-sizing: border-box;
   color: #191919;
   padding: 15px 15px 15px 35px;
   width: 100%;
 }

 .input.password {
   padding: 15px 35px 15px 15px;
 }

 .input-icon {
   color: #191919;
   position: absolute;
   width: 20px;
   height: 20px;
   left: 12px;
   top: 50%;
   transform: translateY(-50%);
 }

 .input-icon.password {
   left: unset;
   right: 12px;
 }

 .transition-3 {
   transition: all 0.33s ease;
   --webkit-transition: all 0.33s ease;
   -moz-transition: all 0.33s ease;
 }

 .transition-5 {
   transition: all 0.5s ease;
   --webkit-transition: all 0.5s ease;
   -moz-transition: all 0.5s ease;
 }


 .bg-optimum-item {
   background-color: #1ca3234a;
 }

 .bg-warning-item {
   background-color: #f7e02f9b;
 }

 .bg-empty-item {
   background-color: #f72f2f47;
 }

 /* Estilo base para ModalOperator */
 .modalOperator {
   display: grid;
   grid-template-columns: repeat(12, 1fr);
   /* 12 columnas */
   grid-template-rows: repeat(8, 1fr);
   /* 8 filas */
   gap: 10px;
   /* Espacio entre grid items */
 }

 /* Ajustes para pantallas menores a 1920x1080 */
 @media (max-width: 1920px) {
   .modalOperator {
     /* Ajustes opcionales para mejorar la visualización en pantallas más pequeñas */
     /* Por ejemplo, puedes reducir el gap o ajustar el tamaño del contenedor */
     gap: 5px;
   }
 }



 /* ! Revisar css que afecta los botones  */
 /* .btn-check+.btn:hover {
   font-weight: 700;
   color: var(--blanco);
   background-color: var(--primary-vending);
   border-color: var(--primary-vending);
 }

 .btn-check:checked+.btn,
 .btn.active,
 .btn.show,
 .btn:first-child:active,
 :not(.btn-check)+.btn:active {
   font-weight: 700;
   border: 1px solid var(--primary-vending);
   color: var(--blanco);
   background-color: var(--primary-vending);
 }
 
   .btn-check+.btn:hover {
     font-weight: 700;
     color: var(--blanco);
     background-color: var(--primary-vending);
     border-color: var(--primary-vending);
   }

   .btn-check:checked+.btn,
   .btn.active,
   .btn.show,
   .btn:first-child:active,
   :not(.btn-check)+.btn:active {
     font-weight: 700;
     border: 1px solid var(--primary-vending);
     color: var(--blanco);
     background-color: var(--primary-vending);
   }

   .btn-check+.btn:hover {
  font-weight: 700;
  color: var(--primario);
  background-color: var(--primario-light);
  border-color: var(--primario);
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  font-weight: 700;
  border: 1px solid var(--primario);
  color: var(--primario);
  background-color: var(--primario-light);
  border-color: var(--primario);
}
 */

 @media (max-width: 412px) {
    .imgEditModal {
        width: 65%;
        height: 211px;
        border-radius: 15px;
        object-fit: cover;
        cursor: pointer;
    }
}

