.container-header-sp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}




.banner-sp {
    background-color: #ffffff;
    /* border: 1px solid #ffffff; */
}

.banner-sp-title {
    color: #000000;
    font-size: 35px;
    font-weight: 700;
}

.banner-sp-subtitle {
    display: inline;
    color: #000000;
    font-size: 20px;
    font-weight: 500;
}

.banner-sp-description {
    display: inline;
    color: #000000;
    font-size: 15px;
    font-weight: 500;

}

.total-card-ht {
    border-top: 1px dashed #53da4e8c;
    background-color: #f4fcf33d;
}

.text-card-ht {
    color: #20b01c;

}

.card-ht {
    margin: 5px;
    border: 1px solid #dee2e6;

}

.card-ht:hover {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    border: 2px solid rgba(197, 197, 197, 0.418);
}

/*  */
.btn-ht-update:active {
    transform: scale(0.95);
}


@media (max-width: 912px) {

    .banner-sp {

        margin-top: 75px;
        margin-bottom: 20px;
        background-color: #ffffff;
        /* border: 1px solid #ffffff; */
    }

    .banner-sp-title {
        color: #165116;
        font-size: 25px;
        font-weight: 700;
    }

}


@media (max-width: 767px) {

    .banner-sp {

        margin-top: 75px;
        margin-bottom: 20px;
        background-color: #ffffff;
        /* border: 1px solid #ffffff; */
    }

    .banner-sp-title {
        color: #165116;
        font-size: 25px;
        font-weight: 700;
    }

}


@media (max-width: 412px) {

    .banner-sp {

        margin-top: 75px;
        margin-bottom: 20px;
        background-color: #ffffff;
        /* border: 1px solid #ffffff; */
    }

    .banner-sp-title {
        color: #165116;
        font-size: 25px;
        font-weight: 700;
    }

}


.text-dark-800 {

    color: #3F4254;
}

.label-card {

    color: #B5B5C3;
}

.border-dashed {
    border: 1px dashed #dfdfdf; 
}