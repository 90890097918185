.aurora-container {
 position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
/* background-image: repeating-linear-gradient(90deg, #070b340f 0px, rgba(7, 11, 52, 0.06) 1px, transparent 1px, transparent 13px), repeating-linear-gradient(45deg, rgba(7, 11, 52, 0.06) 0px, rgba(7, 11, 52, 0.06) 1px, transparent 1px, transparent 13px), linear-gradient(90deg, rgb(27, 29, 92), #102e95);  */
  overflow: hidden;
  background-color: black;
}