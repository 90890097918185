/* Contenedor del formulario */
.formulario-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Estilos de pasos */
.paso {
    display: none;
}

.paso.activo {
    display: block;
    animation: fadeIn 0.3s ease-in-out;
}

/* Animación de transición */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Botones */
.botones-navegacion {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    gap: 10px;
}

.botones-navegacion button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.botones-navegacion button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    display: none;
}

.botones-navegacion button:nth-child(1) {
    background: #a7a7a7;
    color: white;
}

.botones-navegacion button:nth-child(2) {
    background: #28a745;
    color: white;
}